<template>
    <div class="fz-20">
        <b-card class="bg-transparent mb-0">
            <AppPageHeader title="O que é o SCR" />
        </b-card>
        <b-card class="p-3">
            <b-row >
                <b-col cols="12">
                    <h4>O que é o SCR?</h4>
                    <div>
                        <p>O sistema de informações de Crédito do Banco Central do Brasil (SCR) é um banco de <br> dados com
                        informações sobre todas as operações de crédito acima de R$200,00, como <br> empréstimos,
                        financiamentos, avais e fianças, realizadas entre as instituições financeiras ou <br> fintechs e seus
                        clientes.
                    </p>
                    <p>
                        O SCR é diferente do SPC (Serviço de Proteção ao Crédito) ou SERASA, pois é um <br> sistema próprio
                        do Banco Central que não indica apenas os negativos, mas todas as <br> transações financeiras que são
                        realizadas no país.
                    </p>

                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12">
                    <h4>Qual a finalidade do SCR?</h4>
                    <div>
                        <p>Permite que os clientes das instituições financeiras ou fintechs o acompanhem as <br> informações de
                        suas operações de crédito que estão registradas no SCR, trazendo maior <br> transparência. Sem
                        desrespeitar o sigilo bancário.</p>

                        <p>Permite que o Banco Central do Brasil obtenha informações mais precisas para o controle <br> das
                        operações de crédito praticadas pelas instituições financeiras ou fintechs autorizadas, <br>
                        oferecendo maior credibilidade e segurança ao público que consome os serviços e produtos <br> destas
                        empresas.
                        </p>

                        <p>Permite que as instituições financeiras ou fintechs façam uma melhor avaliação da <br> capacidade de
                        pagamento de seus clientes, garantindo que as aprovações sejam feitas com <br> mais segurança e
                        precisão. Com isso, também conseguem praticar taxas de juros <br> adequadas ao perfil de crédito dos
                        seus clientes através de uma análise individualizada.
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {BCard, BCol, BRow } from 'bootstrap-vue';
import AppPageHeader from '@/@core/components/app-page-header/AppPageHeader.vue';

export default {
    name: 'ScrHelp',
    components: {
        AppPageHeader,
        BCard,
        BRow,
        BCol
    }
}

</script>